import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    Box,
} from '@chakra-ui/react';

import {IoMdAdd} from 'react-icons/io'
import {AiOutlineMinus} from 'react-icons/ai'
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function ProductFAQ() {
    const [faqData, setFaqData] = useState([]);
    const url = process.env.REACT_APP_BACKEND_BASE_URL;
    const {product} = useSelector((state) => state.product);
    console.log("this is product beta ",product)
    useEffect(() => {
        if(product?.id){
        const fetchData = async () => {
          try {
            const response = await fetch(`${url}/api/faq/get-product-faq/${product?.id}/`, {
              method: "GET",
              headers: {
                "Content-type": "application/json"
              }
            });
    
            if (response.ok) {
              const jsonData = await response.json();
              setFaqData(jsonData);
            } else {
              console.error("Failed to fetch data");
            }
          } catch (err) {
            console.error(err);
          }
        };
    
        fetchData();
    }
      }, [product]);

    //   const data = faqData.filter((faq) => faq?.faq_category === product?.id);
    return (
        <div className='flex flex-col gap-y-9 pb-[60px]'>
            <div>
                

                <div className='text-[32px] font-medium'>Frequently asked questions</div>
            </div>


            <Accordion allowMultiple>
                {faqData?.results?(
                    faqData?.results?.map((faq) => (
                        <AccordionItem className='border-b-2'>
                            {({ isExpanded }) => (
                                <>
                                    <h2>
                                        <AccordionButton>
                                            <Box as="span" flex='1' textAlign='left' className='text-[18px] font-semibold my-[20px]'>
                                                {faq?.question}
                                            </Box>
                                            {
                                                isExpanded ? (
                                                    <AiOutlineMinus/>
                                                ) : (
                                                        <IoMdAdd/>
                                                    )
                                            }
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4} className='mb-[16px] text-[18px]'>
                                        {faq?.answer}
                                    </AccordionPanel>
                                </>
                            )}
                        </AccordionItem>
                    ))):<p>No Product FAQ Found</p>
                }
            </Accordion>
        
        </div>
    )
}

export default ProductFAQ