import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import InputField from './InputField';
import CtnBtn from './CtnBtn';

const ReportBug = () => {
  const [email, setEmail] = useState("");
  const [user, setUser] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [bugDescription, setBugDescription] = useState("");
  const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL
  const handleSubmit = async () => {
    if (email && user && phoneNumber && bugDescription ) {   
      try {
        const response = await axios.post(`${BASE_URL}/api/contact_us/report-bug/`, {
          email,
          user,
          phone_number: phoneNumber,
          bug_description: bugDescription,
        });
        if(response.status === 201){
        toast.success("Bug report submitted successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setEmail("");
        setUser("");
        setPhoneNumber("");
        setBugDescription("");
    }
      } catch (error) {
        toast.error("Failed to submit bug report. Please try again.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      toast.warn("Please fill in all fields.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <div className="p-[24px] border rounded-xl h-[95%] md:h-[90%] md:ml-[1.5vw] md:mr-[2vw] border-blue w-[56vw] md:w-full md:p-[12px] md:mb-[24px]">
      <h1 className="text-[32px] md:text-[24px] font-medium md:font-[500]">
        Report Bug
      </h1>
      <div className="flex w-full md:flex-col">
        <div className="w-full" onChange={(e) => setEmail(e.target.value)}>
          <InputField
            name="email"
            label="Email"
            type="email"
            placeholder="John@abc.com"
            required
            className="md:font-[400] md:text-black md:text-[12px]"
            value={email}
            
          />
        </div>
        <div className="w-full" onChange={(e) => setUser(e.target.value)}>
          <InputField
            name="user"
            label="User"
            type="text"
            placeholder="John Doe"
            required
            className="md:font-[400] md:text-black md:text-[12px]"
            value={user}
            
          />
        </div>
      </div>
      <div className="flex w-full md:flex-col">
        <div className="w-full"  onChange={(e) => setPhoneNumber(e.target.value)}>
          <InputField
            name="phone_number"
            label="Phone Number"
            type="text"
            placeholder="+91 1234567890"
            required
            className="md:font-[400] md:text-black md:text-[12px]"
            value={phoneNumber}
           
          />
        </div>
      </div>
      <p className="text-[16px] text-custom-text-darkGrey mt-[24px] mb-[10px] md:font-[400] md:text-black md:text-[12px] md:mt-[12px] md:mb-[7px]">
        Bug Description<span className="text-red-500">*</span>
      </p>
      <textarea
        rows={window.innerWidth < 500 ? "3" : "5"}
        placeholder="Describe the bug in detail"
        className="border border-grayLight py-[6px] px-[12px] rounded focus:outline-none w-full md:mb-[16px]"
        value={bugDescription}
        onChange={(e) => setBugDescription(e.target.value)}
      />
      <div onClick={handleSubmit} className="w-[131px] md:w-[60px]">
        <CtnBtn
          className={`mt-[32px] w-[130px] md:w-[74px] md:h-[30px] h-[38px] font-semibold bg-custom-bg-gray text-white rounded cursor-pointer md:mt-0 md:text-[12px] flex items-center justify-center ${
            email && user && phoneNumber && bugDescription
              ? "bg-custom-btn text-white"
              : ""
          }`}
        >
          Report Bug
        </CtnBtn>
      </div>
    </div>
  );
};

export default ReportBug;
