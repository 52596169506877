import { useEffect, useState } from "react";
import { IoShareSocialOutline } from "react-icons/io5";
import { IoMdHeartEmpty } from "react-icons/io";
import Image from '../../assets/img/Bopp_tape.jpg'
import Image2 from '../../assets/img/Boxes_2.jpg'
import { IoMdHeart } from "react-icons/io";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ShareDialog from "./ShareDialog";
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import { setProduct } from "../../redux/slices/productSlice";
import { useUserAddWishMutation, useUserRemoveWishMutation, useUserWhishQuery } from "../../redux/MyAccount/MyAccount";
import { useIndividualProductCustomOptionMutation, useProductDynamicPriceMutation } from "../../redux/individualProduct/individualProduct"; 

function AllProductCard({ item}) {
  const {id:paramId} = useParams();
  const [indiproductData, setIndiproductData] = useState([]);
  const [productPriceData, setProductPriceData] = useState([]);
  const [minQty, setMinQty] = useState();
  const {category} = useParams();
  const [shareModal, setShareModal] = useState(false);
  const dispatch = useDispatch();
  const [ProductCustomOptionApi, {isLoading:optionsLoading, refetch:productCustomRefetch}] = useIndividualProductCustomOptionMutation();
  const [selected_options, setSelectedOptions] = useState({});
  const [num, setNum] = useState(1);
  console.log("Item", item);

  // useEffect(() => {
  //   const options = {};
  //   Object?.entries(indiproductData)?.map(([key, value]) => {
  //     if(Array.isArray(value) && (key != "product_id") && (key != 'Product') && (key != "Product Category")) {
  //       options[key] = value[0];
  //     } else if(typeof value === "string" && (key != 'Product') && (key != "Product Category")) {
  //         options[key] = value;
  //     }
  //   })
  //   setSelectedOptions(options);
  // }, [indiproductData, item?.id])

  useEffect(() => {
    const getProductCustomOptions = async () => {
      try {
        const productBody = {
            product_id: item?.product_id ? item?.product_id : item?.id,
        }

        const response = await ProductCustomOptionApi(productBody);
        if(response) {
            setIndiproductData(response?.data);
            setMinQty(response?.data?.Quantity[0])
        }
      } catch (err) {
        console.log(err);
      }
    }

    getProductCustomOptions();
  }, [category, item?.id])



  return (
    // <div className=" md:shadow-none rounded-[10px] p-[8px] md:p-0 md:px-4  mb-[24px] md:mb-[62px]  md:mr-0 md:h-[238px] h-fit md:w-[48%] sl:w-full" onClick={() => { dispatch(setProduct(item))}}>
    //   <div className="text-custom-text text-[24px] flex justify-end mb-[16px] relative">
    //     <button onClick={()=>setShareModal(!shareModal)}>
    //       <IoShareSocialOutline className="ml-[16px] cursor-pointer" />
    //     </button>
    //     <ShareDialog visibility={shareModal} setVisibility={setShareModal} id={item?.id ? item?.id : item?.product_id} from={!paramId ? "mainPage" : ""}/>
    //   </div>

    //   <Link to={`/allproducts/product/${item?.id ? item?.id : item?.product_id}`} className="flex flex-col items-center md:gap-y-1 gap-y-3">
    //     <img src={`${Image}`} alt="Candle Box" className="md:h-[194px] h-[220px] md:w-[160px] w-[227px] object-cover"/>
    //     <div className="w-full">
    //       <h3 className="md:text-[12px] md:font-normal font-semibold text-[16px]">{item?.title ? item?.title : item?.Product}</h3>
    //       {<p className="md:text-[10px] md:font-normal text-[16px] font-normal">{`Minimum quantity ${minQty ? minQty : 25} pieces`}</p>}
    //     </div>
    //   </Link>

    //   <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} />
    // </div>

    <div className={`group transition-all duration-200 ease-in`} onClick={() => { dispatch(setProduct(item))}}>
      <div className="relative">
        <button onClick={()=>setShareModal(!shareModal)} className="text-custom-text text-[24px] flex justify-end absolute top-0 right-0 left-0 p-3 group-hover:z-[50] transition-all duration-200 ease-in ">
          <IoShareSocialOutline className="cursor-pointer text-white" />
        </button>
        <ShareDialog visibility={shareModal} setVisibility={setShareModal} id={item?.id ? item?.id : item?.product_id} from={!paramId ? "mainPage" : ""}/>
      </div>
      {/* <ShareDialog visibility={shareModal} setVisibility={setShareModal} id={item?.id ? item?.id : item?.product_id} from={!paramId ? "mainPage" : ""}/> */}
      
      <Link to={`/allproducts/product/${item?.id ? item?.id : item?.product_id}`} className="relative flex flex-col items-center gap-y-2 transition-all duration-200 ease-in-out " style={{ borderRadius: '12px', overflow: 'hidden' }}>
        <img src={item?.image?item?.image:Image2} alt="Candle Box" className=" w-full h-64 md:h-auto max-w-[450px] object-cover  group-hover:scale-105  group-hover:rounded-2xl group-hover:shadow-[rgba(0,_0,_0,_0.2)_0px_60px_40px_-7px] transition-all duration-200 ease-in"/>
        <div className="w-full h-0 absolute bottom-0 right-0 group-hover:h-full bg-gradient-to-t to-[#00a6db] from-white group-hover:scale-105 group-hover:rounded-2xl transition-all duration-200 ease-in-out opacity-60"></div>
        {/* <div className="w-full h-0 absolute bottom-0 right-0 group-hover:h-full text-2xl sm:text-base font-bold text-[#00a6db] transition-all duration-500 ease-in-out opacity-0 group-hover:opacity-100 sl:hidden"> <span className="text-lg sm:text-[12px]">Price :</span> ₹{item?.price}</div> */}
        <div className="w-full h-0 absolute bottom-0 group-hover:top-8 right-0 group-hover:h-full text-[20px] sm:text-base font-bold text-white transition-all duration-500 ease-in-out opacity-0 group-hover:opacity-100 sl:hidden px-4"> <span className="text-lg sm:text-[12px] font-medium ml-1.5">Category :</span> {item?.category_name}</div>
        <div className="w-full hidden bottom-0  group-hover:flex group-hover:flex-col transition-all duration-500 ease-in-out">
          <h3 className="md:text-[12px] md:font-normal font-semibold text-[16px]">{item?.title ? item?.title : item?.Product}</h3>
          {/* {<p className="md:text-[10px] md:font-normal text-[16px] font-normal">{`Minimum quantity ${minQty ? minQty : 25} pieces`}</p>} */}
        </div>
        <div className="w-full group-hover:hidden transition-all duration-500 ease-in-out">
          <h3 className="md:text-[12px] md:font-normal font-semibold text-[16px]">{item?.title ? item?.title : item?.Product}</h3>
          {/* {<p className="md:text-[10px] md:font-normal text-[16px] font-normal">{`Minimum quantity ${minQty ? minQty : 25} pieces`}</p>} */}
        </div>
      </Link>

      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} />
    </div>
      
  )
}

export default AllProductCard;