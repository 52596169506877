import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;
export const Addresses = createApi({
  reducerPath: "Address",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
    credentials:'same-origin'
  }),
  tagTypes: ["ViewCart","ViewAddress","CartTotal"],
  endpoints: (builder) => ({
    UserAddresCreate: builder.mutation({
      query: (userAddress) => ({
        url: "/api/user/addresses/create/",
        method: "POST",
        body: userAddress,
      }),
      providesTags:["ViewAddress"]
    }),
    AddToCart: builder.mutation({
      query: (cartData) => ({
        url: "/api/productsheet/add-to-cart/",
        method: "POST",
        body: cartData,
        credentials: 'include'
      }),
      invalidatesTags: ["ViewCart"],
    }),
    getUserAddress: builder.query({
      query: () => ({
        url: "/api/user/addresses/",
      }),
      invalidatesTags: ["ViewAddress"],
    }),
    UpdateUserAddress: builder.mutation({
      query: (userAddress) => ({
        url: `/api/user/addresses/13/update/`,
        method: "PUT",
        body: userAddress,
      }),
    }),
    UserCartView: builder.query({
      query: () => ({
        url: "/api/productsheet/view-cart/",
        credentials: 'include',
      }),
      providesTags: ["ViewCart"],
    }),
    CartIncreaser: builder.mutation({
      query: (id) => ({
        url: `/api/product/add-to-cart/${id}/`,
        method: "POST",
      }),
      invalidatesTags: ["ViewCart"],
    }),
    CartDecreaser: builder.mutation({
      query: (id) => ({
        url: `/api/product/remove-to-cart/${id}/`,
        method: "POST",
      }),
      invalidatesTags: ["ViewCart"],
    }),
    CartRemove: builder.mutation({
      query: (id) => ({
        url: `/api/productsheet/delete-cart-item/${id}/`,
        method: "DELETE",
        credentials: 'include'
      }),
      invalidatesTags: ["ViewCart"],
    }),
    MoveToCart:builder.mutation({
      query:(id)=>({
        url:`/api/productsheet/move-to-cart/${id}/`,
        method:'POST'
      }),
      invalidatesTags: ["ViewCart"],
    }),
    UpdateCartQuantity:builder.mutation({
      query:(body)=>({
        url:`/api/productsheet/cart-quantity-change/`,
        method:'POST',
        body:body,
        credentials: 'include'
      }),
      invalidatesTags: ["ViewCart"],
    }),
    RelatedProductCart:builder.mutation({
      query:(data)=>({
        url:`/api/productsheet/related-products/`,
        method:'POST',
        body:data
      })
    }),
    DoCheckbox:builder.mutation({
      query:(data)=>({
        url:'/api/productsheet/select-checkout-status/',
        method:'POST',
        body:data
      }),
      invalidatesTags: ["CartTotal"], 
    }),
    CartCalculation:builder.query({
      query:()=>({
        url:`/api/productsheet/get-checkout-items/`,
        credentials: 'include'
      }),
      invalidatesTags: ["CartTotal"],
    }),
    AddressSelection:builder.mutation({
      query:(data)=>({
          url:'/api/productsheet/select-shipping-address/',
          method:'POST',
          body:data
      })
    })
  }),
});

export const {
  useUserAddresCreateMutation,
  useGetUserAddressQuery,
  useUpdateUserAddressMutation,
  useCartIncreaserMutation,
  useCartDecreaserMutation,
  useCartRemoveMutation,
  useUserCartViewQuery,
  useAddToCartMutation, 
  useMoveToCartMutation,
  useUpdateCartQuantityMutation,
  useRelatedProductCartMutation,
  useCartCalculationQuery,
  useDoCheckboxMutation,
  useAddressSelectionMutation
} = Addresses;
