import { Link } from "react-router-dom";
import { Tooltip } from '@chakra-ui/react'
import Logo from '../assets/img/Logo.png';
import { AiFillInstagram, AiFillFacebook } from "react-icons/ai";
import { SiGooglemybusiness } from "react-icons/si";
import { FaLinkedin } from "react-icons/fa6";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordian2 from "./accordian/Accordian2";
import { useNavigate,useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';


import { useGetAllCategoryQuery, useGetAllProductQuery, useSearchedProductsMutation } from "../redux/productrtk/Product";
import { useEffect, useState } from "react";

function Footer() {
    const navigate = useNavigate()
    const location = useLocation()
    const hideAccordianPaths = [
        '/allproducts',
        '/allproducts/:category',
        '/allproducts/industry/:industry',
        '/allproducts/product/:id'
    ];
    const token = localStorage.getItem("token")

    const shouldHideAccordian = hideAccordianPaths.some(path => 
        new RegExp(`^${path.replace(/:\w+/g, '[^/]+')}$`).test(location.pathname)
    );
    
    const handleInstagramClick = () => {
        window.location.href = 'https://www.instagram.com/inkprintindia';
      };      
      const {data: products, isError, isLoading:productLoading, refetch:productRefetch} = useGetAllProductQuery();
      const [productCategory, setProductCategory] = useState();
    

    
      useEffect(() => {
        setProductCategory(products);
      }, [products])
    return (
        <>
            <div className="pt-8 px-[40px] font-lato pb-[60px] container_maxWidth_1440 block md:hidden text-[14px] z-[100] text-[#2F2F2F]">
  <h3 className="text-[32px] font-medium mb-[20px] pl-[16px]">
    Our Products
  </h3>
  <div className="pr-[15px] flex gap-[24px] justify-center items-center text-[14px] flex-wrap ml-5 mr-5 md:hidden">
    {
      products ? (
        products.map((product, index) => (
          <div className="w-[150px]" key={index}>
            <Link to={`/allproducts/product/${product?.id}`} className="hover:text-custom-text transition duration-300">{product?.title}</Link>
          </div>
        ))
      ) : (<p>Loading.....</p>)
    }
  </div>
                <div className="flex justify-center mx-[400px] my-[40px]">
                    <hr className="border-t-2 border-green w-full" />
                </div>
                <div className="text-[14px] ml-5 mr-5 flex justify-between">
                    <div className="w-1/5 mr-20">
                      <Link to='/'>
                      <img src={Logo} alt="inkprint logo" />
                      </Link>
                        <p className="font-lato pl-[15px] text-[14px] font-normal leading-[18px]">
                            Copyright &copy; 2024 <span className="text-custom-text-green font-lato text-[14px] font-normal">Inkprint</span>,<br/> All Rights Reserved.
                        </p>
                    </div>
                    <div className="w-1/5">
                        <h3 className="text-[20px] font-medium mb-[16px] text-black">
                            Information
                        </h3>
                        <ul className="text-[14px] font-lato font-normal text-[#2f2f2f]">
                            <ol className="mb-[16px] hover:text-custom-text transition duration-300">
                                <Link to="/aboutus" onClick={() => window.scrollTo({top: 0, behavior:'smooth'})}>About Us</Link>
                            </ol>
                            <ol className="mb-[16px] hover:text-custom-text transition duration-300">
                                <Link to="/contactus">Contact Us</Link>
                            </ol>
                            <ol className="mb-[16px] hover:text-custom-text transition duration-300">
                                <Link to="/AllBlogs">Blog</Link>
                            </ol>
                            <ol className="mb-[16px] hover:text-custom-text transition duration-300">
                                <Link to="/privacypolicy">Privacy Policy</Link>
                            </ol>
                            <ol className="mb-[16px] hover:text-custom-text transition duration-300">
                                <Link to="/termsandconditions">Terms &amp; Conditions</Link>
                            </ol>
                        </ul>
                    </div>
                    <div className="w-1/5">
                        <h3 className="text-[20px] font-medium mb-[20px] text-black">
                            My Account
                        </h3>
                        <ul>
                            <ol className="mb-[16px] hover:text-custom-text transition duration-300"
                             onClick={()=>{
                                if(!token){
                                    toast.warning("Please Login!")
                                    return
                                }
                                navigate("/myaccountDetail",{state:"account details"})
                                
                             }}
                            >
                                <p className="cursor-pointer">Account Details</p>
                            </ol>
                            <ol className="mb-[16px] hover:text-custom-text transition duration-300"
                            onClick={()=>{
                                if(!token){
                                    toast.warning("Please Login!")
                                    return
                                }
                                navigate("/myaccountDetail",{state:"my orders"})}}
                            >
                                <p className="cursor-pointer">Orders</p>
                            </ol>
                            <ol className="mb-[16px] hover:text-custom-text transition duration-300"
                            onClick={()=>{
                                if(!token){
                                    toast.warning("Please Login!")
                                    return
                                }
                                navigate("/myaccountDetail",{state:"my whislist"})}}
                            >
                                <p className="cursor-pointer">Wishlist</p>
                            </ol>
                            <ol className="mb-[16px] hover:text-custom-text transition duration-300">
                                <Link to="/account/cart">Cart</Link>
                            </ol>
                            <ol className="mb-[16px] hover:text-custom-text transition duration-300">
                                <Link to="/FAQs">FAQs</Link>
                            </ol>
                            <ol className="mb-[10px] hover:text-custom-text transition md:text-[14px] md:font-[500] duration-300 text-[15px] text-[#2f2f2f]">
                                <Link to="/return">Return Policy</Link>
                            </ol>
                        </ul>
                    </div>
                    <div className="w-1/5">
                        <h3 className="text-[20px] pl-2 font-medium mb-[20px] text-black">
                            Follow Us On
                        </h3>
                        <ul className="flex  text-[25px]"> 
                            <ol className=" mr-[13px]">
                                <Tooltip label="Instagram" className="bg-black text-white text-[12px] px-[8px] py-[5px] rounded-lg">
                                    <Link to="https://www.instagram.com/inkprintindia/">
                                        <AiFillInstagram className="text-[#2F2F2F] hover:text-custom-text transition duration-300"/>
                                    </Link>
                                </Tooltip>
                            </ol>

                            <ol className="mr-[13px]">
                                <Tooltip label="Facebook" className="bg-black text-white text-[12px] px-[8px] py-[5px] rounded-lg">
                                    <Link to="https://www.facebook.com/inkprintdotin/">
                                        <AiFillFacebook className="text-[#2F2F2F] hover:text-custom-text transition duration-300"/>
                                    </Link>
                                </Tooltip>
                            </ol>
                            <ol className="mr-[13px]">
                                <Tooltip label="Linkedin " className="bg-black text-white text-[12px] px-[8px] py-[5px] rounded-lg">
                                    <Link to="https://www.linkedin.com/company/turtle-media/">
                                    <FaLinkedin className="text-[#2F2F2F] hover:text-custom-text transition duration-300 "/>

                                    </Link>
                                </Tooltip>
                            </ol>

                            <ol className="mr-[13px]">
                                <Tooltip label="Google Business Profile" className="bg-black text-white text-[12px] px-[8px] py-[5px] rounded-lg">
                                    <Link to="https://maps.app.goo.gl/13tUn5k4m4LY12k88">
                                        <SiGooglemybusiness  className="text-[#2f2f2f] hover:text-custom-text transition duration-300 "/>
                                    </Link>
                                </Tooltip>
                            </ol>
                        </ul>
                    </div>
                    <div className="w-1/5 text-[#2F2F2F]">
                        <h3 className="text-[20px] font-medium mb-[20px]">
                            Address
                        </h3>
                        <p>
                            Turtle Media Pvt. Ltd., 9 DSIDC Sheds, IInd Floor, Okhla Industrial Area Phase I,
                            New Delhi - 110020, India
                        </p>
                        <p className="py-[10px]">
                            <span className="font-semibold">
                                Phone:
                            </span>
                            <a href="tel:9772906080"> +91-977-290-6080 (India)</a>
                        </p>
                        <p>
                            <span className="font-semibold">Email: </span>
                            <a href="mailto:contact@inkprint.in">Contact@Inkprint.In</a>
                        </p>
                        <Link to="https://www.banao.tech/" className="text-[1px] text-white hover:cursor-none">Banao</Link>
                    </div>
                </div>
            </div>
            {/* this section for mobile footer */}
            <div className="w-[100vw] md:mt-[-35px]  hidden md:block">
                <div className="w-[100%] mt-1 pl-5 mb-2 md:mt-[20%]">
                {!shouldHideAccordian && <h1 className="text-[17px] text-[#2f2f2f] font-semibold font-lato">Products & Categories</h1>}
                    
                </div>

                <div className="accordian2 border-none outline-none">
                {!shouldHideAccordian && <Accordian2 />}
                    
                </div>

                <div className="flex justify-center mx-[10px] my-[40px] ">
                    <hr className="border-t-2 border-green w-1/3" />
                </div>

                <div className="flex justify-between  ml-[8vw] mr-[15vw]">
                    <div className="font-lato">
                        <h4 className="text-[14px] font-semibold text-[#2f2f2f] font-lato mb-[10px]">Information</h4>
                        <ul>
                            <ol className="mb-[10px] hover:text-custom-text-green transition duration-300  md:text-[14px] md:font-medium text-[12px] text-[#2f2f2f]">
                                <Link to="/aboutus">About Us</Link>
                            </ol>
                            <ol className="mb-[06px] hover:text-custom-text-green transition duration-300 md:text-[14px] md:font-medium  text-[12px] text-[#2f2f2f]">
                                <Link to="/contactus">Contact Us</Link>
                            </ol>
                            <ol className="mb-[10px] hover:text-custom-text-green transition duration-300 md:text-[14px] md:font-[500] text-[12px] text-[#2f2f2f]">
                                <Link to="/AllBlogs">Blog</Link>
                            </ol>
                            <ol className="mb-[10px] hover:text-custom-text-green transition duration-300 md:text-[14px] md:font-[500]  text-[12px] text-[#2f2f2f]">
                                <Link to="/privacypolicy">Privacy Policy</Link>
                            </ol>
                            <ol className="mb-[10px] hover:text-custom-text-green transition duration-300 md:text-[14px] md:font-[500] text-[12px] text-[#2f2f2f]">
                                <Link to="/termsandconditions">Terms &amp; Conditions</Link>
                            </ol>
                        </ul>
                    </div>
                    <div className="">
                        <h4 className="text-[14px] font-semibold text-[#2f2f2f] mb-[10px]">My Account</h4>
                        <ul>
                            <ol className="mb-[10px] hover:text-custom-text-green transition md:text-[14px] md:font-[500] duration-300 text-[8px] text-[#2f2f2f]"
                             onClick={()=>{
                                if(!token){
                                    toast.warning("Please Login!")
                                    return
                                }
                                navigate("/myaccountDetail",{state:"account details"})
                                
                             }}
                            >
                                <p>Account Details</p>
                            </ol>
                            <ol className="mb-[10px] hover:text-custom-text-green transition md:text-[14px] md:font-[500] duration-300 text-[8px] text-[#2f2f2f]"
                             onClick={()=>{
                                if(!token){
                                    toast.warning("Please Login!")
                                    return
                                }
                                navigate("/myaccountDetail",{state:"my orders"})}}
                            >
                              <p>Orders</p>
                            </ol>
                            <ol className="mb-[10px] hover:text-custom-text-green transition md:text-[14px] md:font-[500] duration-300 text-[8px] text-[#2f2f2f]"
                            onClick={()=>{
                                if(!token){
                                    toast.warning("Please Login!")
                                    return
                                }
                                
                                navigate("/myaccountDetail",{state:"my whislist"})}}
                            >
                                <p>Wishlist</p>
                            </ol>
                            <ol className="mb-[10px] hover:text-custom-text-green transition md:text-[14px] md:font-[500] duration-300 text-[8px] text-[#2f2f2f]">
                                <Link to="/account/cart">Cart</Link>
                            </ol>
                            <ol className="mb-[10px] hover:text-custom-text-green transition md:text-[14px] md:font-[500] duration-300 text-[8px] text-[#2f2f2f]">
                                <Link to="/FAQs">FAQs</Link>
                            </ol>
                            <ol className="mb-[10px] hover:text-custom-text-green transition md:text-[14px] md:font-[500] duration-300 text-[8px] text-[#2f2f2f]">
                                <Link to="/return">Return Policy</Link>
                            </ol>
                        </ul>
                        </div>
                    </div>
                    <div className="mt-[24px] text-[#2f2f2f] text-[14px] w-3/4 ml-[5vw] md:ml-[7vw] md:w-[50vw]">
                        <h4 className="text-[14px] font-semibold text-[#2f2f2f] mb-[10px]">Address</h4>
                        <p className="font-normal text-[14px] w-full font-inter">Turtle Media Pvt. Ltd., 9 DSIDC Sheds, IInd Floor, Okhla Industrial Area Phase I, New Delhi - 110020, India</p>
                        <p className="py-[8px] font-inter text-[14px] w-[80vw]">
                            <span className="font-medium text-[#2f2f2f]">
                                Phone:
                            </span>
                            <a href="tel:9772906080" className="font-normal"> +91-977-290-6080 (India)</a>
                        </p>
                        <p className="font-inter text-[14px] w-[80vw]">
                            <span className="font-medium">Email: </span>
                            <a href="mailto:contact@inkprint.in">Contact@Inkprint.In</a>
                        </p>
                    </div>
                    <div className="mt-[30px] flex justify-between items-center ml-[5vw] pb-[2vh] gap-x-4">
                        <div className="w-[68vw]">
                            <img src={Logo} alt="logo" className="w-[78px]"/>
                            
                            <p className="text-[#2f2f2f] font-inter text-[14px]  w-full">
                                Copyright &copy; 2024 <span className="text-custom-text-green">Inkprint</span>, All Rights Reserved.
                            </p>
                        </div>
                        <div className="mr-[15vw] w-[32vw]">
                            <h4 className="text-[14px] font-semibold text-[#2f2f2f] mb-[10px]">Follow Us On</h4>
                            <div>
                                <ul className="flex text-[16px] text-custom-text-grey">
                            
                                    <ol className="hover:text-custom-text-green transition duration-300 mr-[18px]" onClick={handleInstagramClick}><AiFillInstagram className="md:w-[24px] md:h-[24px] text-[#2f2f2f]" /></ol>
                            
                                    <ol className="hover:text-custom-text-green transition duration-300 mr-[18px]"><Link to="/"><AiFillFacebook className="md:w-[24px] md:h-[24px] text-[#2f2f2f]" /></Link></ol>
                                    <Link to="https://maps.app.goo.gl/13tUn5k4m4LY12k88"><ol className="hover:text-custom-text-green transition duration-300 mr-[18px]"><SiGooglemybusiness className="text-[#2f2f2f] md:w-[24px] md:h-[24px]" /></ol></Link>
                                
                                    <ol>
                                        <Tooltip label="linkedin " className=" text-white text-[12px] px-[2px]">
                                        <Link to="https://www.linkedin.com/company/turtle-media/">
                                            <FaLinkedin className=" md:w-[24px] md:h-[24px] text-[#2f2f2f] hover:text-custom-text transition duration-300 "/>
                                        </Link>
                                        </Tooltip>
                                    </ol>
                            
                                </ul>
                                <Link to="https://www.banao.tech/" className="text-[1px] text-white hover:cursor-none">Banao</Link>
                            </div>
                        </div>
                    </div>
            </div>
            {/* <ToastContainer/> */}
        </>
    )
}

export default Footer